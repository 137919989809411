<template>
  <CustomCursor/>
  <div class="container pt-48 mx-auto space-y-16 text-gray-900">
    <div>
      <span class="small-text">UX/UI Design</span>
      <h1 class="pt-4 text-4xl font-semibold md:text-5xl lg:text-7xl">
        UOK - Building an app for students' wellbeing
      </h1>
    </div>
    <div class="flex space-x-28">
      <div class="flex space-x-12">
        <div class="flex flex-col">
          <span class="small-text">Company</span>
          <p>UOK</p>
        </div>
        <div>
          <span class="small-text">Role</span>
          <ul>
            <li>User Research</li>
            <li>Product Strategy</li>
            <li>UX Design</li>
            <li>UI Design</li>
            <li>Prototyping</li>
            <!-- <li>Usability Testing</li> -->
          </ul>
        </div>
        <div>
          <span class="small-text">Tools</span>
          <ul>
            <li>FigJam</li>
            <li>Figma</li>
            <!-- <li>Maze</li> -->
          </ul>
        </div>
        <div>
          <span class="small-text">Timeline</span>
          <ul>
            <li>5&nbsp;weeks</li>
          </ul>
        </div>
      </div>
      <div>
        <p>UOK is a product designed to help teachers proactively support their student's wellbeing. Every day students log in to UOK and answer a series of questions expressing how they feel. The goal was to improve this daily wellness check-in experience, by making it more appealing for students to answer questions each day and be able to visualise their wellness journey with UOK.</p>
        <p class="pt-4">I worked on this case study during Memorisely UX/UI Design immersive online Bootcamp, collaborating with a small team of designers on the UOK experience.</p>
      </div>
    </div>
    <img src="/assets/img/CaseStudies/UOK/case-study-uok.png" alt="uok">
    <div class="w-2/3 m-auto">
      <h3>The Problem</h3>
      <p>The main problem we identified from our research and after formulating a hypothesis, was that students couldn’t express their emotions effectively and feel the experience personal. The current experience is repetitive and boring, which results in frustration and loss of engagement.</p>
      <div class="inline-block p-8 mt-8 text-2xl border-2 border-gray-400 border-dotted">
        <span class="italic font-medium leading-8">
          We believe that creating a more engaging, personalised and appealing experience for high school students will <span class="highlight" :style="{ backgroundImage: BackgroundColor('#86b32d5b') }">encourage them to use the app regularly</span> and <span class="highlight" :style="{ backgroundImage: BackgroundColor('#c859ff5b') }">better enjoy the check-in experience</span>.
        </span>
        <div class="flex items-center pt-6 space-x-3">
          <span class="inline-block w-5 h-5 rounded-full" style="background-color:#86b32d5b;"></span>
          <span class="text-xl">Business goal</span>
        </div>
        <div class="flex items-center pt-2 space-x-3">
          <span class="inline-block w-5 h-5 rounded-full" style="background-color:#c859ff5b;"></span>
          <span class="text-xl">User goal</span>
        </div>
      </div>
    </div>
    <div class="w-2/3 m-auto">
      <h3>The Solution</h3>
      <p>The final solution comes with a more fun and engaging check-in experience, introducing different ways to answer questions, such as sliders or smilies, plus adding a free space where students can expand their feelings. By adding navigation, now there's a space where they can see the results to keep track of their mood over time. Extra content has also been added to the home section, such as the "Reminder of the day" or the daily exercise to encourage students to come back every day with new content. The UI is more consistent. Also, I gave great importance to the copy, with the aim to create empathy with students.</p>
    </div>
  </div>
  <img class="pt-16" src="/assets/img/CaseStudies/UOK/uok-screens.png" alt="uok screens">
  <div class="container pt-16 mx-auto space-y-16 text-gray-900">
    <h2 class="flex items-center text-4xl font-semibold md:text-5xl lg:text-7xl">
      Full Process&nbsp;
      <svg class="mt-6 transform scale-150 rotate-90" width="24" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 7h22m0 0s-4-1.5-6.177-6M22 7s-4 1.5-6.177 6" stroke="#424242" stroke-width="1.2"/></svg>
    </h2>
    <div class="w-2/3 m-auto">
      <h3>Usability Review</h3>
      <p>I initially began by conducting a usability review to help me better understand the UOK app from user’s perspective. This allowed me to identify pain points and wow moments in the existing experience.</p>
    </div>
    <img src="/assets/img/CaseStudies/UOK/usability-review.png" alt="usability review">
    <div class="w-2/3 m-auto">
      <h3>Competitor Benchmarking</h3>
      <p>With a usability review complete, I moved on to competitor benchmarking to help me identify standards in competitor products that could be used to improve the existing experience.</p>
    </div>
    <div class="flex items-start">
      <div><img src="/assets/img/CaseStudies/UOK/competitor-1.png" alt="competitor 1"></div>
      <div><img src="/assets/img/CaseStudies/UOK/competitor-2.png" alt="competitor 2"></div>
      <div><img src="/assets/img/CaseStudies/UOK/competitor-3.png" alt="competitor 3"></div>
    </div>
    <div class="w-2/3 m-auto">
      <h3>Questions & Observations</h3>
      <p>To help me better frame any problems with the product identified during my usability review, I formed some questions and observations I have about the product. To easily document these I followed the structure <span class="font-semibold" style="color:#CA7E62">[situation], [response], [problem to business or experience]</span> to ensure I'm aware of users and business needs. After that I could form my hypothesis.</p>
      <div class="inline-block p-8 mt-8 text-2xl text-gray-500 border-2 border-gray-400 border-dotted">
        <p>
          <span class="text-xl">📌</span> When<span class="font-medium text-gray-800"> answering questions </span>
          students<span class="font-medium text-gray-800"> can only choose a coloured number between 1-5</span>,
          which causes<span class="font-medium text-gray-800"> an experience without personalisation that feels like an homework, limiting users’ expression on how they’re feeling with less chance to receive support from teachers.</span>
        </p>
        <p class="pt-8">
          <span class="text-xl">📌</span> When<span class="font-medium text-gray-800"> the daily check-in is completed </span>
          students<span class="font-medium text-gray-800"> can't do anything else and the experience ends there</span>,
          which causes<span class="font-medium text-gray-800"> loss of engagement, leading them to not use the app.</span>
        </p>
      </div>
    </div>
     <div class="w-2/3 m-auto">
      <h3>Synthesis</h3>
      <p>With a hypothesis set, the next step was to synthesise real survey data gathered from the product team to identify pain points from existing users. I segmented users' responses and then sorted them into an affinity map, where I grouped information to help prioritise the main problems.</p>
    </div>
  </div>
  <div class="p-20"><img src="/assets/img/CaseStudies/UOK/affinity.png" alt="affinity map"></div>
  <div class="container mx-auto space-y-16 text-gray-900">
    <div class="w-2/3 m-auto">
      <h3>Validated observations</h3>
      <p>The key patterns and trends I identified in my affinity map helped me to validate my early observations on users’ main frustrations. The biggest problem is the lack of personalisation, students need more fun and personal space where they can express their thoughts freely without being limited. They also can’t see their answers and progress over time. I identified the primary frustration based on what could most benefit both users and business.</p>
      <div class="inline-block p-8 mt-8 text-2xl text-gray-500 border-2 border-gray-400 border-dotted">
        <div>
          <h4 class="text-gray-900"><span class="text-2xl">📌</span> Primary Frustration</h4>
          <p>When <span class="font-medium text-gray-800">answering questions with a 1-5 rating system</span>, students are <span class="font-medium text-gray-800">limited in their answers and they don’t feel the experience personal</span>, which results in <span class="font-medium text-gray-800">lack of engagement and support from teachers.</span></p>
        </div>
        <div class="pt-8">
          <h4 class="text-gray-900"><span class="text-2xl">📌</span> Secondary Frustration</h4>
          <p>When <span class="font-medium text-gray-800">completing the daily check-in</span>, students are <span class="font-medium text-gray-800">just done with the experience and unable to see their answers</span>, which results <span class="font-medium text-gray-800">in repetitiveness and ineffective method to track feelings over time.</span></p>
        </div>
      </div>
    </div>
    <div class="w-2/3 m-auto">
      <h3>How might we</h3>
      <p>With a picture of the problem at hand starting to come into place, we jumped into the ideation phase and worked through the solution design model, identifying users actual behaviour, and optimal behaviour. This allowed me to form a how might we statement to begin forming a solution.</p>
      <div class="inline-block p-8 mt-8 text-2xl text-gray-900 border-2 border-gray-400 border-dotted">
        <p><span class="font-semibold">💡 How might we</span> create a more personal and engaging space for students to help them better express their feelings and receive the support they need?</p>
      </div>
    </div>
    <div class="w-2/3 m-auto">
      <h3>Ideation</h3>
      <p>To avoid following the first idea I conducted a series of ideation techniques. This allowed me to consider an array of solutions. Following ideation I mapped what could be improved or added to the product, prioritising them based on user value, business value, effort and time.</p>
    </div>
  </div>
  <div class="px-20 pt-8 pb-12"><img src="/assets/img/CaseStudies/UOK/mind-map.png" alt="mind map"></div>
  <div class="container mx-auto space-y-16 text-gray-900">
    <div class="w-2/3 m-auto">
      <h3>User Flows</h3>
      <p>Following Ideation I created user flows of the existing experience and improved the flow based on the idea that fit with business and user goals.</p>
      <p class="pt-4">To inform the user flows I created a user story to ensure each user flow had a goal.</p>
      <div class="inline-block p-8 mt-8 text-2xl border-2 border-gray-400 border-dotted">
        <span class="italic font-medium leading-8">As a high school student, I want to express how I feel without being limited when answering so I can gain the help I need from teachers.</span>
      </div>
    </div>
    <h4 class="w-2/3 m-auto">Existing and improved User Flows:</h4>
  </div>
  <div class="px-20 pt-8"><img src="/assets/img/CaseStudies/UOK/existing-flow.png" alt="existing user flow"></div>
  <div class="px-20"><img src="/assets/img/CaseStudies/UOK/improved-flow.png" alt="improved user flow"></div>
  <div class="container mx-auto mt-20 space-y-10 text-gray-900">
    <div class="w-2/3 m-auto">
      <h3>Wireframes</h3>
        <p>To quickly visualize my ideas I first made some sketches, then I turned them into Mid Fidelity Wireframes using a neutral color palette to avoid any decision bias. I used Autoflow in Figma to help me easily map the user flow between each page and use this prototype to get feedback internally.</p>
    </div>
    <img src="/assets/img/CaseStudies/UOK/sketches.png" alt="rapid sketches">
  </div>
  <div class="px-20 pt-8"><img src="/assets/img/CaseStudies/UOK/wireframes-prototype.png" alt="rapid sketches"></div>
  <div class="container mx-auto space-y-16 text-gray-900 mt-28">
    <div class="w-2/3 m-auto">
      <h3>Styles & Components</h3>
      <p>To create the high fidelity prototype I followed the 8pt rule to effectively and easily create a prototype that was consistent with the product styling. Before creating the prototype I defined styles and components to easily and quickly help me design consistently.</p>
      <p class="my-4">
        For the look and feel and UI elements of the app I wanted something that could evoke balance, inner peace and make students in a safe space where they could share their feelings in full trust. I was inspired by the Pantone color of the year Veri Peri, which also encourages personal inventiveness and creativity. I also made sure to check the contrast ratios of colors to create a more accessible app.
      </p>
      <p>I chose Poppins for headings to give a fresh and modern touch, paired with Open Sans that has a more neutral style.</p>
    </div>
    <img src="/assets/img/CaseStudies/UOK/styles.png" alt="styles 1">
    <img src="/assets/img/CaseStudies/UOK/styles-2.png" alt="styles 2">
    <img src="/assets/img/CaseStudies/UOK/components.png" alt="styles 2">
    <div class="w-2/3 pt-8 m-auto">
      <h3>High Fidelity Prototype</h3>
      <p>Below is the final version of the prototype that I created. I included interactions and transitions from Figma to match the products flow.</p>
    </div>
    <video controls src="/assets/img/CaseStudies/UOK/prototype-compressed.mp4"></video>
    <div class="relative z-30 flex pt-12">
      <router-link to="/">
        <h3 class="flex items-center">
          Back Home 
          <svg class="mt-6 ml-6 transform scale-150" width="24" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 7h22m0 0s-4-1.5-6.177-6M22 7s-4 1.5-6.177 6" stroke="#424242" stroke-width="1.2"/></svg>
        </h3>
      </router-link>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Footer from '@/components/Footer.vue';
  import CustomCursor from '@/components/CustomCursor.vue';

  export default {
    name: "UOK",
    computed: {
    BackgroundColor() {
        return color => `linear-gradient(white 50%, ${color} 50%`
      }
    },
    components: {
      Footer,
      CustomCursor
    },
  }
</script>

<style scoped>

  q { font-size: 1.25rem; }

  p, ul, li {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .highlight {
    background-position-y: 25%;
    transition: background 500ms ease;
    background-size: 2px;
    background-size: auto 175%;
  }

  .highlight:hover {
    background-position-y: 100%;
  }

  @media screen and (min-width: 1024px) {
    p {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    q { font-size: 1.5rem; }

    ul, li {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 400;
    }
  }

</style>